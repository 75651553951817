var base = require("../product/base");
var commonFeature = require("../components/commonFeature");
var $window = $(window);
const debounce = require("lodash/debounce");
var saveForLaterList = require("../product/saveForLaterList");
const wishlist = require("../product/wishlistHeart");

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl +=
        (newUrl.indexOf("?") !== -1 ? "&" : "?") +
        Object.keys(params)
            .map(function (key) {
                return key + "=" + encodeURIComponent(params[key]);
            })
            .join("&");

    return newUrl;
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            var errorHtml =
                "<div class='alert alert-danger alert-dismissible valid-cart-error " +
                "fade show' role='alert'>" +
                "<button type='button' class='close' data-dismiss='alert' aria-label='Close'>" +
                "<span aria-hidden='true'>&times;</span>" +
                "</button>" +
                data.valid.message +
                "</div>";

            $(".cart-error").append(errorHtml);
        } else {
            $("<div class='cart-empty'><div class='row'> " +
                    "<div class='col-12 text-center cart-empty-title'> " +
                    "<div class='text-h1'>" +
                    data.resources.emptyCartMsg +
                    "</div> " +
                    "<a class='continue-shopping-link button-primary-dark' href='" + data.urls.continueShopping + "' " +
                    "title='" + data.urls.continueShoppingLink + "'>" +
                    data.urls.continueShoppingLink +
                    "</a> " +
                    "</div> " +
                    "</div></div>").insertAfter(".cart-container-header");
            $(".cart-wrapper").addClass("is-empty-cart").find(".cart-sidebar-background").remove();
            $(".cart-wrapper").find(".cart-page-row").removeClass("col-md-9").addClass("col-12");
            $(".cart-wrapper").find(".continue-shopping-link__wrapper").remove();
            $(".cart-wrapper").find(".product-cards-wrapper").remove();
            $(".cart-wrapper").find(".approaching-discounts").remove();
            $(".cart-wrapper").find(".sticky-secure-checkout").remove();
            $(".cart-wrapper").find(".cart-sidebar").remove();
            $(".number-of-items").empty().append(data.resources.numberOfItems);
            $(".minicart-quantity").empty().append(data.numItems);
            if (data.numItems > 0) {
                $(".minicart-quantity").removeClass("d-none");
                $(".minicart-link").removeClass("minicart-empty");
            } else {
                $(".minicart-quantity").addClass("d-none");
                $(".minicart-link").addClass("minicart-empty");
            }
            $(".minicart-link").attr({
                "aria-label": data.resources.minicartCountOfItems,
                title: data.resources.minicartCountOfItems
            });
            $(".minicart .popover").empty();
            $(".minicart .popover").removeClass("show");
            $(".modal-background").removeClass("d-block");
        }

        $(".checkout-btn").addClass("disabled");
    } else if (data.isMaterialQtyExceeded) {
        $(".checkout-btn").addClass("disabled d-none");
        $(".checkout-btn-swatch-error").removeClass("d-none");
    } else {
        $(".checkout-btn-swatch-error").addClass("d-none");
        $(".checkout-btn").removeClass("disabled d-none");
    }
    
}

function updateEstimatedDelivery(data, $form) {
    var $estimatedDeliveryTitle = $form.find(".estimated-delivery-title");
    if (data.deliveryData && data.deliveryData.zipcode) {
        $form.find(".cart-estimated-delivery-zipcode-form-group").addClass("d-none");
        $form.find(".estimated-delivery-total").removeClass("d-none");

        if (data.deliveryData.isShipmentZip) {
            $form.find("button[type='reset']").addClass("d-none");
        } else {
            $form.find("button[type='reset']").removeClass("d-none");
        }


        var estimatedDeliveryTitle = $estimatedDeliveryTitle.data("estimated-delivery-to-msg");

        if (data.totals) {
            var shippingCost = 0;
            if (data.totals.shippingLevelDiscountTotal.value > 0) {
                shippingCost = data.totals.discountedShippingCost;

                if (data.totals.discountedShippingCostVal > 0) {
                    estimatedDeliveryTitle = $estimatedDeliveryTitle.data("discounted-delivery-msg");
                } else {
                    estimatedDeliveryTitle = $estimatedDeliveryTitle.data("free-delivery-msg");
                }
            } else {
                shippingCost = data.totals.totalShippingCost;
            }
            $form.find(".estimated-delivery-total").text(shippingCost);
        }

        $estimatedDeliveryTitle.text(estimatedDeliveryTitle + " " + data.deliveryData.zipcode);
    } else {
        $form.find(".invalid-feedback").hide();
        $form.find(".cart-estimated-delivery-zipcode-form-group").removeClass("d-none");
        $form.find(".estimated-delivery-total").addClass("d-none");
        $form.find("button[type='reset']").addClass("d-none");

        $estimatedDeliveryTitle.text($estimatedDeliveryTitle.data("estimated-delivery-msg"));
    }

    if (data.action === "Cart-UpdateShippingZip") {
        if (data.deliveryData && !data.deliveryData.deliveryZipCode) {
            $form.find(".cart-estimated-delivery-zipcode").addClass("nondelivery-zipcode");
            $form.find(".estimated-delivery-nondelivery-zipcode-msg").removeClass("d-none");
        } else {
            $form.find(".cart-estimated-delivery-zipcode").removeClass("nondelivery-zipcode");
            $form.find(".estimated-delivery-nondelivery-zipcode-msg").addClass("d-none");
        }
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    var affirmLimits = { min: data.affirmPaymentMinTotal, max: data.affirmPaymentMaxTotal };
    $(".number-of-items").empty().append(data.resources.numberOfItems);
    if (data.isShippingAddress) {
        $(".shipping-cost").empty().append(data.totals.totalShippingCost);
    }
    if (data.isShippingAddress) {
        $(".tax-total").empty().append(data.totals.totalTax);
    }

    $(".grand-total-cart").empty().append(data.totals.estimatedTotal);
    if (data.fullPriceObj.isSales) {
        $(".sub-total").empty().append(data.fullPriceObj.formatted);
        $(".grand-total").empty().append(data.fullPriceObj.adjustedPrice + "&nbsp;&nbsp;");
    } else {
        $(".grand-total").empty().append(data.totals.subTotal);
        $(".sub-total").empty();
    }

    if (data.fullPriceObj.totalSavingsFormatted) {
        $(".cart-total-savings-value").empty().append(data.fullPriceObj.totalSavingsFormatted);
        $(".cart-total-savings").removeClass("d-none");
    } else {
        $(".cart-total-savings").addClass("d-none");
    }

    // Affirm
    var totalCalculated = data.totals.grandTotal.substr(1).replace(/,/g, "");
    $(".affirm-as-low-as").attr("data-amount", (totalCalculated * 100).toFixed());
    var isWithinAffirmLimit =
        parseFloat(totalCalculated) >= parseFloat(affirmLimits.min) &&
        parseFloat(totalCalculated) <= parseFloat(affirmLimits.max);
    if (isWithinAffirmLimit) {
        $("#js-affirm-checkout-now").show();
    } else {
        $("#js-affirm-checkout-now").hide();
        $(".affirm-as-low-as").attr("data-amount", NaN);
    }

    var affirmenabled = $(".affirmenabled").data("affirmenabled");

    if (affirmenabled) {
        affirm.ui.refresh();
    }

    // Affirm
    $(".minicart-quantity").empty().append(data.numItems);
    if (data.numItems > 0) {
        $(".minicart-quantity").removeClass("d-none");
        $(".minicart-link").removeClass("minicart-empty");
    } else {
        $(".minicart-quantity").addClass("d-none");
        $(".minicart-link").addClass("minicart-empty");
    }
    $(".minicart-link").attr({
        "aria-label": data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems
    });
    if (data.idmeDiscountObj.orderDiscountWithoutIDME > 0) {
        $(".order-discount-total")
            .empty()
            .append("- " + data.idmeDiscountObj.orderDiscountWithoutIDMEFormatted);
        $(".order-discount").removeClass("d-none");
        $(".order-discount").addClass("d-flex");
    } else {
        $(".order-discount").removeClass("d-flex");
        $(".order-discount").addClass("d-none");
        $(".order-discount-total")
            .empty()
            .append(data.totals.orderLevelDiscountTotal.formatted);
    }

    // Synchrony
    var $synchronyCartPrice = $(".synchrony-widget-cart-price");
    if ($synchronyCartPrice) {
        $synchronyCartPrice.text(data.totals.estimatedTotal);
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $(".shipping-discount").removeClass("hide-shipping-discount");
        $(".shipping-discount-total")
            .empty()
            .append("- " + data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $(".shipping-discount").addClass("hide-shipping-discount");
    }

    if (data.idmeDiscountObj) {
        if (data.idmeDiscountObj.orderDiscount < 0) {
            $(".idme-section .idme-discount-total").text(data.idmeDiscountObj.orderDiscountFormatted).removeClass("d-none");
            $(".idme-section .idme-discount-applied").addClass("d-none");
        } else {
            $(".idme-section .idme-discount-total").empty().addClass("d-none");
            $(".idme-section .idme-discount-applied").removeClass("d-none");
        }
    }

    data.items.forEach(function (item) {
        if (data.totals.orderLevelDiscountTotal.value > 0) {
            $(".coupons-and-promos").empty().append(data.totals.discountsHtml);
        }
        if (item.renderedPromotions) {
            $(".item-" + item.UUID)
                .empty()
                .append(item.renderedPromotions);
        } else {
            $(".item-" + item.UUID).empty();
        }

        $(".item-total-" + item.UUID)
            .empty()
            .append(item.priceTotal.renderedPrice);

        $(".line-item-price-" + item.UUID + " .unit-price")
            .empty()
            .append(item.renderedPrice);
    });

    updateEstimatedDelivery(data, $("form.estimated-delivery"));
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    var errorHtml =
        "<div class='alert alert-danger alert-dismissible valid-cart-error " +
        "fade show' role='alert'>" +
        "<button type='button' class='close' data-dismiss='alert' aria-label='Close'>" +
        "<span aria-hidden='true'>&times;</span>" +
        "</button>" +
        message +
        "</div>";

    $(".cart-error").append(errorHtml);
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts) {
    var html = "";
    $(".approaching-discounts").empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach(function (item) {
            html += "<div class='single-approaching-discount text-center'>" + item.discountMsg + "</div>";
        });
    }
    $(".approaching-discounts").append(html);
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    var lineItem;
    var messages = "";

    for (var i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    if (lineItem != null) {
        $(".availability-" + lineItem.UUID).empty();

        if (lineItem.availability) {
            if (lineItem.availability.messages) {
                lineItem.availability.messages.forEach(function (message) {
                    messages += "<p class='line-item-attributes'>" + message + "</p>";
                });
            }

            if (lineItem.availability.inStockDate) {
                messages +=
                    "<p class='line-item-attributes line-item-instock-date'>" +
                    lineItem.availability.inStockDate +
                    "</p>";
            }
        }

        $(".availability-" + lineItem.UUID).html(messages);
    }
}

/**
 * Updates details of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateProductDetails(data, uuid) {
    $(".card.product-info.uuid-" + uuid).replaceWith(data.renderedTemplate);
}

/**
 * replace content of modal
 * @param {string} actionUrl - url to be used to remove product
 * @param {string} productID - pid
 * @param {string} productName - product name
 * @param {string} uuid - uuid
 */
function confirmDelete(actionUrl, productID, productName, uuid) {
    var $deleteConfirmBtn = $(".cart-delete-confirmation-btn");
    var $productToRemoveSpan = $(".product-to-remove");

    $deleteConfirmBtn.data("pid", productID);
    $deleteConfirmBtn.data("action", actionUrl);
    $deleteConfirmBtn.data("uuid", uuid);

    $productToRemoveSpan.empty().append(productName);
}

/**
 * ajax request for remove item from cart
 * @param {string} url - url to be used to remove product
 */
function handleRemoveItemsAjax(url, uuid) {
    $.ajax({
        url: url,
        type: "get",
        dataType: "json",
        success: function (data) {
            if (data && "emptySaveForLaterListMsg" in data && !("basket" in data)) {
                $(".row.cart-product__wrapper.uuid-" + uuid + " + .cart-save-for-later-line").remove();
                $(".row.cart-product__wrapper.uuid-" + uuid).remove();

                if ($(".cart-save-for-later-container .row.cart-product__wrapper").length == 0) {
                    $(".cart__save-for-later").remove();
                }
            } else {
                if (data.basket.items.length === 0) {
                    $("<div class='cart-empty'><div class='row'> " +
                        "<div class='col-12 text-center cart-empty-title'> " +
                        "<div class='text-h1'>" +
                        data.basket.resources.emptyCartMsg +
                        "</div> " +
                        "<a class='continue-shopping-link button-primary-dark' href='" + data.basket.urls.continueShopping + "' " +
                        "title='" + data.basket.urls.continueShoppingLink + "'>" +
                        data.basket.urls.continueShoppingLink +
                        "</a> " +
                        "</div> " +
                        "</div></div>").insertAfter(".cart-container-header");
                    $(".cart-wrapper").addClass("is-empty-cart").find(".cart-sidebar-background").remove();
                    $(".cart-wrapper").find(".cart-page-row").removeClass("col-md-9").addClass("col-12");
                    $(".cart-wrapper").find(".continue-shopping-link__wrapper").remove();
                    $(".cart-wrapper").find(".product-cards-wrapper").remove();
                    $(".cart-wrapper").find(".approaching-discounts").remove();
                    $(".cart-wrapper").find(".sticky-secure-checkout").remove();
                    $(".cart-wrapper").find(".cart-sidebar").remove();
                    $(".number-of-items").empty().append(data.basket.resources.numberOfItems);
                    $(".minicart-quantity").empty().append(data.basket.numItems);
                    if (data.basket.numItems > 0) {
                        $(".minicart-quantity").removeClass("d-none");
                        $(".minicart-link").removeClass("minicart-empty");
                    } else {
                        $(".minicart-quantity").addClass("d-none");
                        $(".minicart-link").addClass("minicart-empty");
                    }
                    $(".minicart-link").attr({
                        "aria-label": data.basket.resources.minicartCountOfItems,
                        title: data.basket.resources.minicartCountOfItems
                    });
                    $(".minicart .popover").empty();
                    $(".minicart .popover").removeClass("show");
                    $(".modal-background").removeClass("d-block");
                    $("body").removeClass("modal-open");
                    $("html").removeClass("veiled");
                } else {
                    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                        for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                            $(".uuid-" + data.toBeDeletedUUIDs[i]).remove();
                        }
                    }
                    if (uuid) {
                        $(".uuid-" + uuid).remove();
                    }
                    if (!data.basket.hasBonusProduct) {
                        $(".bonus-product").remove();
                    }
                    $(".coupons-and-promos").empty().append(data.basket.totals.discountsHtml);
                    updateCartTotals(data.basket);
                    updateApproachingDiscounts(data.basket.approachingDiscounts);
                    $("body").trigger("setShippingMethodSelection", data.basket);
                    validateBasket(data.basket);
                }

                if (!data.removeUnavailableItems) {
                    $(".remove-unavailable-items").remove();
                }

                if (!data.isMaterialQtyExceeded) {
                    $(".remove-material-items").addClass("d-none");
                    $(".checkout-btn").removeClass("disabled");
                } else {
                    $(".remove-material-items").removeClass("d-none");
                    $(".checkout-btn").addClass("disabled");
                }

                $("body").trigger("cart:update", data);
            }
            setTimeout(function () {
                window.location.reload();
            }, 500);
            $.spinner().stop();
        },
        error: function (err) {
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            } else {
                createErrorNotification(err.responseJSON.errorMessage);
                $.spinner().stop();
            }
        }
    });
}

/**
 * set paraments for item move from cart to wishlist
 * @param {Object} data - data object with properties to be stored on confirm modal
 */
function confirmMoveWishlist(data) {
    var $wishlistConfirmBtn = $(".move-to-wishlist-confirmation");
    var $productToRemoveSpan = $(".product-to-remove");

    var urlParams = {
        pid: data.productID,
        uuid: data.uuid
    };

    var removeFromCart = appendToUrl(data.removeFromCartUrl, urlParams);

    $wishlistConfirmBtn.data("pid", data.productID);
    $wishlistConfirmBtn.data("action", removeFromCart);
    $wishlistConfirmBtn.data("uuid", data.uuid);
    $wishlistConfirmBtn.data("url", data.addToWishlistUrl);
    $wishlistConfirmBtn.data("recipeId", data.recipeId);
    $wishlistConfirmBtn.data("recipeLocation", data.recipeLocation);
    $wishlistConfirmBtn.data("listType", data.listType);
    $wishlistConfirmBtn.data("bundleParentSku", data.bundleParentSKU);
    $wishlistConfirmBtn.data("bundleParentName", data.bundleParentName);

    $productToRemoveSpan.empty().append(data.productName);
}

/**
 * show sticky checkout button on mobile
 */
function handleStickyCta() {
    var $stickyBar = $(".sticky-secure-checkout");
    var $checkoutBtn= $(".checkout-btn__wrapper");

    if (!$checkoutBtn || !$checkoutBtn.length || !$stickyBar || !$stickyBar.length) {
        return;
    }

    if (commonFeature.isInViewport($checkoutBtn, true)) {
        $stickyBar.removeClass("fixedbottom");
    } else {
        $stickyBar.addClass("fixedbottom");
    }
}

/**
 * ajax request for remove item from Save for later list
 * @param {string} url - url to be used to remove product from list
 */
function removeSaveForLaterItem(url) {
    if (url) {
        $.spinner().start();
        $.ajax({
            url: url,
            type: "get",
            dataType: "json",
            data: {},
            success: function () {
                window.location.reload();
                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    }
}

function handlePostCartAdd(response) {
    $(".minicart").trigger("count:update", response);
    var messageType = response.error ? "alert-danger" : "alert-success";

    if ($(".add-to-cart-messages").length === 0) {
        $("body").append("<div class='add-to-cart-messages'></div>");
    }

    $(".add-to-cart-messages").append(
        "<div class='alert " +
            messageType +
            " add-to-basket-alert text-center' role='alert'>" +
            response.message +
            "</div>"
    );

    setTimeout(function () {
        $(".add-to-basket-alert").remove();
    }, 5000);
}

/**
 * Ajax request for update quantity
 * @param {Object} element - quantity field which will updated
 */
function updateQty(element) {
    var $qtySelector = element;
    var preSelectQty = $qtySelector.data("pre-select-qty");
    var productID = $qtySelector.data("pid");
    var url = $qtySelector.data("action");
    var uuid = $qtySelector.data("uuid");
    var value = $qtySelector.val();

    if (!url || !productID) {
        return;
    }

    if (value == 0) {
        value = 1;
    }

    if (value == 1) {
        $qtySelector.siblings(".btn-decrease-quantity").addClass("disabled");
    } else {
        $qtySelector.siblings(".btn-decrease-quantity").removeClass("disabled");
    }

    var urlParams = {
        pid: productID,
        quantity: value,
        uuid: uuid
    };

    url = appendToUrl(url, urlParams);

    $qtySelector.parents(".card").spinner().start();

    $("body").trigger("cart:beforeUpdate");

    $.ajax({
        url: url,
        type: "get",
        context: this,
        dataType: "json",
        success: function (data) {
            $(".quantity[data-uuid='" + uuid + "']").val(value);
            $(".coupons-and-promos").empty().append(data.totals.discountsHtml);
            updateCartTotals(data);
            updateApproachingDiscounts(data.approachingDiscounts);
            updateAvailability(data, uuid);
            validateBasket(data);

            if (!data.isMaterialQtyExceeded) {
                $(".remove-material-items").addClass("d-none");
            } else {
                $(".remove-material-items").removeClass("d-none");
            }

            $(".quantity[data-uuid='" + uuid + "']").data("pre-select-qty", value);

            $("body").trigger("cart:update", data);

            $.spinner().stop();
            if ($qtySelector.parents(".product-info").hasClass("bonus-product-line-item") && $(".cart-page").length) {
                window.location.reload();
            }
        },
        error: function (err) {
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            } else {
                createErrorNotification(err.responseJSON.errorMessage);
                $(".quantity[data-uuid='" + uuid + "']").val(parseInt(preSelectQty, 10));
                $.spinner().stop();
            }
        }
    });
}


function callCartUpdateShippingZip($form) {
    $.spinner().start();
    $.ajax({
        url: $form.attr("action"),
        type: "GET",
        dataType: "json",
        data: $form.serialize(),
        success: function (data) {
            if (data.error) {
                createErrorNotification(data.errorMessage);
            } else {
                var cart = data.cart;
                if (cart && cart.deliveryData) {
                    cart.deliveryData.deliveryZipCode = data.deliveryZipCode;
                }
                
                if (cart) {
                    cart.isMaterialQtyExceeded = data.isMaterialQtyExceeded;
                }
                if (cart) {
                    cart.action = data.action;
                }

                updateCartTotals(cart);
                updateApproachingDiscounts(cart.approachingDiscounts);
                validateBasket(cart);
            }

            $.spinner().stop();
        },
        error: function (err) {
            if (err.responseJSON.redirectUrl) {
                window.location.href = err.responseJSON.redirectUrl;
            } else {
                createErrorNotification(err.errorMessage);
                $.spinner().stop();
            }
        }
    });
}

module.exports = function () {

    saveForLaterList.addToList();

    /**
     * Handle "Remove" button click on "Save For Later" section in cart.
     */
    $("body").on("click", ".cart-wrapper .remove-from-list", function (e) {
        e.preventDefault();
        var url = $(this).data("url");
        removeSaveForLaterItem(url);
    });

    /**
     * Handle "Move to Cart" button click on "Save For Later" section in cart.
     */
    $("body").on("click", ".add-to-cart-save-for-later", function () {
        var pid = $(this).data("pid");
        var addToCartUrl = $(this).data("url");
        var pidsQty = parseInt($(this).closest(".product-info").find(".quantity").val(), 10) || 1;
        var removeUrl = $(this).data("removeurl");
        if (addToCartUrl && pid) {
            $("body").trigger("product:beforeAddToCart", this);
            var form = {
                pid: pid,
                quantity: pidsQty
            };

            if ($(this).data("option")) {
                form.options = JSON.stringify($(this).data("option"));
            }

            $(this).trigger("updateAddToCartFormData", form);
            $.ajax({
                url: addToCartUrl,
                method: "POST",
                data: form,
                success: function (data) {
                    handlePostCartAdd(data);
                    $("body").trigger("product:afterAddToCart", data);
                    $.spinner().stop();
                    removeSaveForLaterItem(removeUrl);
                    base.miniCartReportingUrl(data.reportingURL, data.error);
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
    });

    $("body").on("click", ".move-to-list", function (e) {
        e.preventDefault();

        var data = {
            removeFromCartUrl:$(this).data("action"),
            productID: $(this).data("pid"),
            productName: $(this).data("name"),
            uuid: $(this).data("uuid"),
            addToWishlistUrl: $(this).attr("href"),
            recipeId: $(this).data("recipeId"),
            recipeLocation: $(this).data("recipeLocation"),
            listType: $(this).data("listType"),
            bundleParentName: $(this).data("bundleParentName"),
            bundleParentSKU: $(this).data("bundleParentSku")
        };

        confirmMoveWishlist(data);
    });

    $("body").on("removeItemFromCartAjax", function (e, url, uuid) {
        handleRemoveItemsAjax(url, uuid);
    });

    $("body").on("click", ".remove-product", function (e) {
        e.preventDefault();

        var actionUrl = $(this).data("action");
        var productID = $(this).data("pid");
        var productName = $(this).data("name");
        var uuid = $(this).data("uuid");
        confirmDelete(actionUrl, productID, productName, uuid);
    });

    $("body").on("afterRemoveFromCart", function (e, data) {
        e.preventDefault();
        confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
    });

    $(".optional-promo").click(function (e) {
        e.preventDefault();
        $(".promo-code-form").toggle();
    });

    $("body").on("click", ".cart-delete-confirmation-btn", function (e) {
        e.preventDefault();

        var productID = $(this).data("pid");
        var url = $(this).data("action");
        var uuid = $(this).data("uuid");
        var urlParams = {
            pid: productID,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $("body > .modal-backdrop").remove();

        $.spinner().start();

        $("body").trigger("cart:beforeUpdate");

        handleRemoveItemsAjax(url, uuid);
    });

    $("body").on("click", ".quantity-form .btn-change-quantity", function () {
        const $button = $(this);
        const $input = $button.siblings(".quantity-select");
        const value = +$input.val();
        const valueMax = $input.data("max-qty");
        const productID = $input.data("pid");
        const url = $input.data("action");

        if (!url || !productID) {
            return;
        }

        if ($button.hasClass("btn-increase-quantity") && value < valueMax) {
            $input.val(value + 1);
        }

        if ($button.hasClass("btn-decrease-quantity") && value > 1) {
            $input.val(value - 1);
        }

        $input.trigger("input");
    });

    $("body").on("input", ".quantity-select", function () {
        const $input = $(this);
        const value = $input.val();
        const valueMax = $input.data("max-qty");

        $input.val(value.replace(/\D/g, "").substring(0, 2));

        if (value && value <= valueMax) {
            updateQty($input);
        }

        $(".modal.show .update-cart-url").data("selected-quantity", value);
    });

    $("body").on("blur", ".quantity-select", function () {
        const $input = $(this);
        const value = $input.val();

        if (!value) {
            $input.siblings(".btn-increase-quantity").trigger("click");
        }
    });

    $(".shippingMethods").change(function () {
        var url = $(this).attr("data-actionUrl");
        var urlParams = {
            methodID: $(this).find(":selected").attr("data-shipping-id")
        };
        //  url = appendToUrl(url, urlParams);

        $(".totals").spinner().start();
        $("body").trigger("cart:beforeShippingMethodSelected");
        $.ajax({
            url: url,
            type: "post",
            dataType: "json",
            data: urlParams,
            success: function (data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $(".coupons-and-promos").empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                }

                $("body").trigger("cart:shippingMethodSelected", data);
                $.spinner().stop();
            },
            error: function (err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $(".promo-code-form").submit(function (e) {
        e.preventDefault();

        const $body = $("body");
        const $form = $(".promo-code-form");
        const $couponField = $form.find(".coupon-code-field");
        const $errorMessage = $(".coupon-error-message");
        const $errorMessageApplied = $(".coupon-applied-error");
        const $errorMessageMissing = $(".coupon-missing-error");
        const $couponsPromosContainer = $(".coupons-and-promos");

        $.spinner().start();

        $errorMessageMissing.hide();
        $errorMessageApplied.hide();
        $errorMessage.empty();

        if (!$couponField.val()) {
            $couponField.addClass("is-invalid").attr("aria-describedby", "missingCouponCode");
            $errorMessageMissing.show();

            $.spinner().stop();

            return false;
        }

        $couponField.removeClass("is-invalid");
        $errorMessage.empty();
        $body.trigger("promotion:beforeUpdate");

        $.ajax({
            url: $form.attr("action"),
            type: "GET",
            dataType: "json",
            data: $form.serialize(),
            success: function (data) {

                if (data.error) {
                    $couponField.addClass("is-invalid").attr("aria-describedby", "invalidCouponCode");
                    $errorMessage.empty().append(data.errorMessage);
                    $body.trigger("promotion:error", data);
                    $(".coupon-code-field").val("");
                } else if (!data.totals.discounts[0].applied) {
                    $couponField.addClass("is-invalid").attr("aria-describedby", "notAppliedCouponCode");
                    $errorMessageApplied.show();
                    $body.trigger("promotion:error", data);
                    $(".coupon-code-field").val("");
                } else {
                    $couponsPromosContainer.empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                    $body.trigger("promotion:success", data);
                }

                $.spinner().stop();
            },
            error: function (err) {
                $body.trigger("promotion:error", err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.errorMessage);
                    $.spinner().stop();
                }
            }
        });
        return false;
    });

    $("body").on("click", ".remove-coupon", function (e) {
        e.preventDefault();

        var couponCode = $(this).data("code");
        var uuid = $(this).data("uuid");
        var $deleteConfirmBtn = $(".delete-coupon-confirmation-btn");
        var $productToRemoveSpan = $(".coupon-to-remove");

        $deleteConfirmBtn.data("uuid", uuid);
        $deleteConfirmBtn.data("code", couponCode);

        $productToRemoveSpan.empty().append(couponCode);
    });

    $("body").on("click", ".delete-coupon-confirmation-btn", function (e) {
        e.preventDefault();

        var url = $(this).data("action");
        var uuid = $(this).data("uuid");
        var couponCode = $(this).data("code");
        var urlParams = {
            code: couponCode,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $("body > .modal-backdrop").remove();

        $.spinner().start();
        $("body").trigger("promotion:beforeUpdate");
        $.ajax({
            url: url,
            type: "get",
            dataType: "json",
            success: function (data) {
                $(".coupon-uuid-" + uuid).remove();
                updateCartTotals(data);
                updateApproachingDiscounts(data.approachingDiscounts);
                validateBasket(data);
                $.spinner().stop();
                $("body").trigger("promotion:success", data);
                $(".coupon-code-field").val("");
            },
            error: function (err) {
                $("body").trigger("promotion:error", err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
                $(".coupon-code-field").val("");
            }
        });
    });
    $("body").on("click", ".cart-page .bonus-product-button", function () {
        $.spinner().start();
        $(this).addClass("launched-modal");
        $.ajax({
            url: $(this).data("url"),
            method: "GET",
            dataType: "json",
            success: function (data) {
                base.methods.editBonusProducts(data);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $("body").on("hidden.bs.modal", "#chooseBonusProductModal", function () {
        $("#chooseBonusProductModal").remove();
        $(".modal-backdrop").remove();
        $("body").removeClass("modal-open");

        if ($(".cart-page").length) {
            $(".launched-modal .btn-outline-primary").trigger("focus");
            $(".launched-modal").removeClass("launched-modal");
        } else {
            $(".product-detail .add-to-cart").focus();
        }
    });

    $("body").on("product:updateAddToCart", function (e, response) {
        //  update global add to cart (single products, bundles)
        var dialog = $(response.$productContainer).closest(".quick-view-dialog");

        $(".update-cart-product-global", dialog).attr(
            "disabled",
            !$(".global-availability", dialog).data("ready-to-order") ||
                !$(".global-availability", dialog).data("available")
        );
    });

    $("body").on("product:updateAvailability", function (e, response) {
        //  bundle individual products
        $(".product-availability", response.$productContainer)
            .data("ready-to-order", response.product.readyToOrder)
            .data("available", response.product.available)
            .find(".availability-msg")
            .empty()
            .html(response.message);

        var dialog = $(response.$productContainer).closest(".quick-view-dialog");

        if ($(".product-availability", dialog).length) {
            //  bundle all products
            var allAvailable = $(".product-availability", dialog)
                .toArray()
                .every(function (item) {
                    return $(item).data("available");
                });

            var allReady = $(".product-availability", dialog)
                .toArray()
                .every(function (item) {
                    return $(item).data("ready-to-order");
                });

            $(".global-availability", dialog).data("ready-to-order", allReady).data("available", allAvailable);

            $(".global-availability .availability-msg", dialog)
                .empty()
                .html(allReady ? response.message : response.resources.info_selectforstock);
        } else {
            //  single product
            $(".global-availability", dialog)
                .data("ready-to-order", response.product.readyToOrder)
                .data("available", response.product.available)
                .find(".availability-msg")
                .empty()
                .html(response.message);
        }
    });

    $("body").on("product:afterAttributeSelect", function (e, response) {
        if ($(".modal.show .product-quickview .bundle-items").length) {
            $(".modal.show").find(response.container).data("pid", response.data.product.id);
            $(".modal.show").find(response.container).find(".product-id").text(response.data.product.id);
        } else {
            $(".modal.show .product-quickview").data("pid", response.data.product.id);
        }
    });

    $("body").on("change", ".options-select", function () {
        var selectedOptionValueId = $(this).children("option:selected").data("value-id");
        $(".modal.show .update-cart-url").data("selected-option", selectedOptionValueId);
    });

    $("body").on("click", ".update-cart-product-global", function (e) {
        e.preventDefault();

        var updateProductUrl = $(this).closest(".cart-and-ipay").find(".update-cart-url").val();
        var selectedQuantity = $(this).closest(".cart-and-ipay").find(".update-cart-url").data("selected-quantity");
        var selectedOptionValueId = $(this).closest(".cart-and-ipay").find(".update-cart-url").data("selected-option");
        var uuid = $(this).closest(".cart-and-ipay").find(".update-cart-url").data("uuid");

        var form = {
            uuid: uuid,
            pid: base.getPidValue($(this)),
            quantity: selectedQuantity,
            selectedOptionValueId: selectedOptionValueId
        };

        $(this).parents(".card").spinner().start();

        $("body").trigger("cart:beforeUpdate");

        if (updateProductUrl) {
            $.ajax({
                url: updateProductUrl,
                type: "post",
                context: this,
                data: form,
                dataType: "json",
                success: function (data) {
                    $("#editProductModal").modal("hide");

                    $(".coupons-and-promos").empty().append(data.cartModel.totals.discountsHtml);
                    updateCartTotals(data.cartModel);
                    updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                    updateAvailability(data.cartModel, uuid);
                    updateProductDetails(data, uuid);

                    if (data.uuidToBeDeleted) {
                        $(".uuid-" + data.uuidToBeDeleted).remove();
                    }

                    validateBasket(data.cartModel);

                    $("body").trigger("cart:update", data);

                    $.spinner().stop();
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
        }
    });

    handleStickyCta();
    $window.on("windowscroll", debounce(handleStickyCta, 50));
    $window.on("windowresize:width", debounce(handleStickyCta, 50));

    wishlist.addToWishlist();

    // remove all unavailable items from cart
    $("body").on("click", ".cart-remove-all", function (e) {
        e.preventDefault();

        var url = $(this).data("url");
        var products = $("input[name=removeCartProducts]").val();
        var urlParams = {
            products: products,
        };

        url = appendToUrl(url, urlParams);

        $.spinner().start();

        $("body").trigger("cart:beforeUpdate");

        if (url) {
            handleRemoveItemsAjax(url);
        }
    });

    $(".estimated-delivery").on("submit", function (e) {
        e.preventDefault();
        var $this = $(this);
        $this.find(".invalid-feedback").hide();
        callCartUpdateShippingZip($this);
        return false;
    });

    $(".estimated-delivery input").on("invalid", function (e) {
        e.preventDefault();
        var $input = $(this);
        var value = $input.val();
        var errorMessage = "";
        if (value && value.length) {
            errorMessage = $(this).data("invalid-zipcode-error");
        } else {
            errorMessage = $(this).data("enter-zipcode-error");
        }
        $(".estimated-delivery .invalid-feedback").text(errorMessage).show();
        $(".estimated-delivery .cart-estimated-delivery-zipcode").removeClass("nondelivery-zipcode");
        $(".estimated-delivery .estimated-delivery-nondelivery-zipcode-msg").addClass("d-none");
    });

    $(".estimated-delivery input").on("input", function () {
        $(".estimated-delivery .cart-estimated-delivery-zipcode").removeClass("nondelivery-zipcode");
    });

    $(".estimated-delivery").on("reset", function () {
        var $this = $(this);
        $this.find("input.cart-estimated-delivery-zipcode").val("");
        callCartUpdateShippingZip($this);
    });

    base.tabAttribute();
    base.selectAttribute();
    base.colorAttribute();
    base.removeBonusProduct();
    base.selectBonusProduct();
    base.enableBonusProductSelection();
    base.showMoreBonusProducts();
    base.addBonusProductsToCart();
    base.focusChooseBonusProductModal();
    base.trapChooseBonusProductModalFocus();
    base.onClosingChooseBonusProductModal();
};
