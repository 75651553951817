var commonFeature = require("./commonFeature");

var $window = $(window);

$(".nav-link").on("shown.bs.tab", function (e) {
    const target = $(e.target).attr("href");

    $(target).find(".js-slick").slick("refresh");
});

var slickCarousel = {
    initPDPSliders: function () {
        var $mainSliders = $("[id^=pdp-main-slider]");
        var initPDPSliderByIndex = this.initPDPSliderByIndex;
        $mainSliders.each(function () {
            initPDPSliderByIndex($(this).data("sliderIndex"));
        });
    },

    initPDPSliderByIndex: function (index, isDrive, isVirtualSku) {
        if (!index && index !== 0) {
            return;
        }
        var $mainSlider = $("#pdp-main-slider-" + index);
        var $thumbSlider = $("#pdp-thumb-slider-" + index);
        var thumbsToShowDesktop = 5;
        var thumbsToShowMobile = 3.5;
        var initialSlide = 0;

        var $customizerMain = $mainSlider.find(".slide-inside--customizer");
        if ($customizerMain.length > 0 && $customizerMain.data("loaded") && isDrive && !isVirtualSku) {
            initialSlide = $mainSlider.find(".slide-inside").length - 1;
        }

        $mainSlider.slick({
            mobileFirst: true,
            slidesToShow: 1,
            infinite: false,
            arrows: false,
            initialSlide: initialSlide
        });

        $thumbSlider.slick({
            mobileFirst: true,
            focusOnSelect: true,
            slidesToShow: thumbsToShowMobile,
            infinite: false,
            arrows: true,
            dots: true,
            asNavFor: $mainSlider,
            initialSlide: initialSlide,
            responsive: [{
                breakpoint: commonFeature.getBreakpointMin("md") - 0.2,
                settings: {
                    slidesToShow: thumbsToShowDesktop,
                    dots: false,
                    initialSlide: initialSlide
                }
            }]
        });

        function updateArrows() {
            var currentSlide = $thumbSlider.slick("slickCurrentSlide");
            var totalSlides = $thumbSlider.slick("getSlick").slideCount;

            if (currentSlide === totalSlides - 1) {
                $(".slick-next").hide();
            } else {
                $(".slick-next").show();
            }

            if (currentSlide === 0) {
                $(".slick-prev").hide();
            } else {
                $(".slick-prev").show();
            }
        }

        $thumbSlider.on("init.slick", function (_event, _slick) {
            $customizerMain = $mainSlider.find(".slide-inside--customizer");
            if ($customizerMain.length > 0 && $customizerMain.data("loaded") && isDrive && !isVirtualSku) {
                var $customizerSlide = $customizerMain.closest(".slick-slide");
                var customizerIndex = $customizerSlide.attr("data-slick-index");

                setTimeout(function () {
                    $thumbSlider.slick("slickGoTo", parseInt(customizerIndex, 10), true);
                }, 0);
            }
            if (commonFeature.isBreakpoint("<sm") && $thumbSlider.find(".slick-slide").length < Math.ceil(thumbsToShowMobile)) {
                $thumbSlider.addClass("small-slider");
            } else if (!commonFeature.isBreakpoint("<sm") && $thumbSlider.find(".slick-slide").length < Math.ceil(thumbsToShowDesktop)) {
                $thumbSlider.addClass("small-slider");
            } else {
                $thumbSlider.removeClass("small-slider");
            }

            updateArrows();
        });

        $thumbSlider.on("click", ".slick-slide:not(.slick-current)", function () {
            var $clickedSlide = $(this);
            if ($clickedSlide.hasClass("slick-current")) {
                return;
            }
            var goToSingleSlide = $clickedSlide.attr("data-slick-index");
            $thumbSlider.slick("slickGoTo", parseInt(goToSingleSlide, 10));
        });

        $thumbSlider.on("afterChange", function () {
            updateArrows();
        });

        $mainSlider.on("afterChange", function (_event, _slick, currentSlide) {
            var $targetSlide = $thumbSlider.find(".slick-slide[data-slick-index=\"" + currentSlide + "\"]");
            if ($targetSlide && !$targetSlide.hasClass("slick-current")) {
                $thumbSlider.slick("slickGoTo", currentSlide);
            }
        });
    },

    init: function () {
        $window.on("windowresize:width", function () {
            $(".slick-slider").each(function () {
                var $this = $(this);

                if ($this.hasClass("slick-initialized")) {
                    $this.slick("refresh");
                }
            });
        });
    },

    slickInit: function () {
        $(".js-slick").slick();
    }
};

module.exports = slickCarousel;
